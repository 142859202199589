import * as React from 'react'
import { generatePath } from 'react-router-dom'
import { routes } from '@constants/routes'
import { HouseId } from '@api/endpoints/buildings/houses'
import { NavTabItem, NavTabs } from '@controls/NavTabs'
import { useHouseFormContext } from '../HouseFormContext'

const tabItems = (houseId: HouseId): ReadonlyArray<NavTabItem> => [
  {
    label: 'Plan images',
    to: generatePath(routes.Admin.HousesEditPlanImages, { houseId })
  },
  {
    label: 'Style exceptions',
    to: generatePath(routes.Admin.HousesEditStyleExceptions, { houseId })
  },
  {
    label: 'Value exceptions',
    to: generatePath(routes.Admin.HousesEditValueExceptions, { houseId })
  }
]

export const HouseFormTabs: React.FC = () => {
  const { house } = useHouseFormContext()

  return <NavTabs tabs={tabItems(house.id)} />
}
