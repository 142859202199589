import * as React from 'react'
import { DeepPartial } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Outlet, generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import * as z from 'zod'
import { isApiError } from '@digital-magic/react-common/lib/api'
import { zodIs } from '@digital-magic/react-common/lib/utils/zod'
import { OptionalType, hasValue, toNullUnionType, toOptionalType, undefinedIf } from '@digital-magic/ts-common-utils'
import { displayDateFormat } from '@constants/configuration'
import { routes } from '@constants/routes'
import { RequestErrorHandler } from '@api/types'
import { IdObject } from '@api/endpoints/types'
import { isDeletableEntity } from '@api/endpoints/utils'
import {
  BuildingId,
  CreateHouseRequest,
  HouseId,
  useCreateHouse,
  useDeleteHouse,
  useGetBuilding,
  useGetBuildings,
  useGetHouse,
  useUpdateHouse
} from '@api/endpoints/buildings/houses'
import { useGetLayoutType } from '@api/endpoints/buildings/layoutTypes'
import { EnergyClassId, useGetEnergyClasses } from '@api/endpoints/settings'
import { useAdminDefaultErrorHandler } from '@hooks/useAdminDefaultErrorHandler'
import { useTranslatedValue } from '@hooks/useTranslatedValue'
import { useSnackbar } from 'notistack'
import { Alert, Box } from '@mui/material'
import { Button } from '@controls/Button'
import { ButtonWithConfirmation } from '@controls/Button/ButtonWithConfirmation'
import { FormDatePickerStyled, FormTextFieldStyled, useFormZod } from '@controls/Form'
import { MenuItemEntry } from '@controls/types'
import { buildEnumOptions } from '@controls/utils'
import { HouseFormContext } from './HouseFormContext'
import { HouseFormTabs } from './HouseFormTabs'
import { HouseFormStyled } from './style'

const HouseFormHistoryState = z.object({
  buildingId: BuildingId
})
export type HouseFormHistoryState = z.infer<typeof HouseFormHistoryState>

const InheritType = z.literal('Inherit')

const InclusionOption = z.enum([InheritType.value, 'Included', 'NotIncluded'])
type InclusionOption = z.infer<typeof InclusionOption>

const inclusionOptionToBool = (v: InclusionOption): OptionalType<boolean> => {
  switch (v) {
    case InheritType.value:
      return undefined
    case 'Included':
      return true
    case 'NotIncluded':
      return false
  }
}

const boolToInclusionOption = (v: OptionalType<boolean>): InclusionOption => {
  switch (v) {
    case undefined:
      return InheritType.value
    case true:
      return 'Included'
    case false:
      return 'NotIncluded'
  }
}

const EnergyClassOrInherit = z.union([InheritType, EnergyClassId])
type EnergyClassOrInherit = z.infer<typeof EnergyClassOrInherit>

const FormValues = CreateHouseRequest.extend({
  hasSauna: InclusionOption,
  isInteriorIncluded: InclusionOption,
  isSanitaryIncluded: InclusionOption,
  energyClassId: EnergyClassOrInherit,
  handoffAt: z.date().nullable()
})
type FormValues = z.infer<typeof FormValues>

type Params = {
  houseId: HouseId
}

export const HouseForm: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams<Params>()
  const location = useLocation()
  const defaultErrorHandler = useAdminDefaultErrorHandler()
  const snackbar = useSnackbar()
  const translateValue = useTranslatedValue()
  const getEnergyClasses = useGetEnergyClasses({ onError: defaultErrorHandler })

  const onCreateHouseSuccess = ({ id }: IdObject): void => {
    navigate(generatePath(routes.Admin.HousesEdit, { houseId: id }), { replace: true })
  }

  const onUpdateHouseSuccess = (): void => {
    void getHouse.refetch()
  }

  const onDeleteHouseSuccess = (): void => {
    navigate(routes.Admin.Houses)
  }

  const onCreateHouseError: RequestErrorHandler = (e) => {
    if (isApiError(e) && e.code === 'AlreadyExistsError') {
      snackbar.enqueueSnackbar(t('pages.admin.house.errors.already_exists'))
    } else {
      defaultErrorHandler(e)
    }
  }

  const onUpdateHouseError: RequestErrorHandler = (e) => {
    if (isApiError(e)) {
      switch (e.code) {
        case 'AlreadyExistsError':
          snackbar.enqueueSnackbar({ message: t('pages.admin.house.errors.already_exists') })
          break
        case 'ConstraintViolationError':
          snackbar.enqueueSnackbar({ message: t('pages.admin.house.errors.update_constraint') })
          break
        default:
          defaultErrorHandler(e)
          break
      }
    }
  }

  const onDeleteHouseError: RequestErrorHandler = (e) => {
    if (isApiError(e) && e.code === 'ConstraintViolationError') {
      snackbar.enqueueSnackbar(t('pages.admin.house.errors.delete_constraint'))
    } else {
      defaultErrorHandler(e)
    }
  }

  const onSubmit = (values: FormValues): void => {
    if (hasValue(params.houseId)) {
      updateHouse.mutate({
        id: params.houseId,
        code: values.code,
        cadastreNumber: values.cadastreNumber,
        apartmentNumber: values.apartmentNumber,
        floor: values.floor,
        outdoorSquare: values.outdoorSquare,
        indoorSquare: values.indoorSquare,
        storeRoomSquare: values.storeRoomSquare,
        terraceSquare: values.terraceSquare,
        roomsCount: values.roomsCount,
        bedroomsCount: values.bedroomsCount,
        hasSauna: inclusionOptionToBool(values.hasSauna),
        isInteriorIncluded: inclusionOptionToBool(values.isInteriorIncluded),
        isSanitaryIncluded: inclusionOptionToBool(values.isSanitaryIncluded),
        energyClassId: undefinedIf((v: EnergyClassId) => v === InheritType.value)(values.energyClassId),
        immovableNumber: values.immovableNumber,
        basePrice: values.basePrice,
        handoffAt: toOptionalType(values.handoffAt)
      })
    } else {
      createHouse.mutate({
        buildingId: values.buildingId,
        code: values.code,
        cadastreNumber: values.cadastreNumber,
        apartmentNumber: values.apartmentNumber,
        floor: values.floor,
        outdoorSquare: values.outdoorSquare,
        indoorSquare: values.indoorSquare,
        storeRoomSquare: values.storeRoomSquare,
        terraceSquare: values.terraceSquare,
        roomsCount: values.roomsCount,
        bedroomsCount: values.bedroomsCount,
        hasSauna: inclusionOptionToBool(values.hasSauna),
        isInteriorIncluded: inclusionOptionToBool(values.isInteriorIncluded),
        isSanitaryIncluded: inclusionOptionToBool(values.isSanitaryIncluded),
        energyClassId: undefinedIf((v: EnergyClassId) => v === InheritType.value)(values.energyClassId),
        immovableNumber: values.immovableNumber,
        basePrice: values.basePrice,
        handoffAt: toOptionalType(values.handoffAt)
      })
    }
  }

  const defaultValues = React.useMemo((): DeepPartial<FormValues> => {
    if (zodIs(location.state, HouseFormHistoryState)) {
      return {
        buildingId: location.state.buildingId
      }
    }

    return {}
  }, [location.state])

  const form = useFormZod<FormValues>({
    schema: FormValues.superRefine((values, ctx) => {
      if (hasValue(values.floor) && hasValue(getLayoutType.data) && values.floor > getLayoutType.data.floorsCount) {
        ctx.addIssue({
          code: 'custom',
          path: ['floor'],
          message: `This building only has ${getLayoutType.data.floorsCount} floors`
        })
      }
    }),
    defaultValues
  })

  const selectedBuildingId = form.watch('buildingId')

  const getBuildings = useGetBuildings(undefined, { onError: defaultErrorHandler })

  const createHouse = useCreateHouse({ onError: onCreateHouseError, onSuccess: onCreateHouseSuccess })
  const updateHouse = useUpdateHouse({ onError: onUpdateHouseError, onSuccess: onUpdateHouseSuccess })
  const deleteHouse = useDeleteHouse({ onError: onDeleteHouseError, onSuccess: onDeleteHouseSuccess })

  const getHouse = useGetHouse(params.houseId ?? '', {
    onError: defaultErrorHandler,
    enabled: hasValue(params.houseId)
  })

  const getBuilding = useGetBuilding(selectedBuildingId, {
    onError: defaultErrorHandler,
    enabled: hasValue(selectedBuildingId)
  })

  const getLayoutType = useGetLayoutType(getBuilding.data?.layoutTypeId ?? '', {
    onError: defaultErrorHandler,
    enabled: hasValue(getBuilding.data?.layoutTypeId)
  })

  React.useEffect(() => {
    if (getHouse.data) {
      form.reset({
        buildingId: getHouse.data.buildingId,
        code: getHouse.data.code,
        cadastreNumber: getHouse.data.cadastreNumber,
        apartmentNumber: getHouse.data.apartmentNumber,
        floor: getHouse.data.floor,
        roomsCount: getHouse.data.roomsCount,
        bedroomsCount: getHouse.data.bedroomsCount,
        immovableNumber: getHouse.data.immovableNumber,
        // TODO: If we use correct types everywhere - we won't need such conversions
        basePrice: hasValue(getHouse.data.basePrice) ? Number(getHouse.data.basePrice) : undefined,
        outdoorSquare: hasValue(getHouse.data.outdoorSquare) ? Number(getHouse.data.outdoorSquare) : undefined,
        indoorSquare: hasValue(getHouse.data.indoorSquare) ? Number(getHouse.data.indoorSquare) : undefined,
        storeRoomSquare: hasValue(getHouse.data.storeRoomSquare) ? Number(getHouse.data.storeRoomSquare) : undefined,
        terraceSquare: hasValue(getHouse.data.terraceSquare) ? Number(getHouse.data.terraceSquare) : undefined,
        hasSauna: boolToInclusionOption(getHouse.data.hasSauna),
        isInteriorIncluded: boolToInclusionOption(getHouse.data.isInteriorIncluded),
        isSanitaryIncluded: boolToInclusionOption(getHouse.data.isSanitaryIncluded),
        energyClassId: hasValue(getHouse.data.energyClassId) ? getHouse.data.energyClassId : InheritType.value,
        handoffAt: toNullUnionType(getHouse.data.handoffAt)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getHouse.data])

  const buildingOptionValues: ReadonlyArray<MenuItemEntry<BuildingId>> = React.useMemo(() => {
    if (getBuildings.data) {
      return getBuildings.data.map((b) => ({
        label: b.address,
        value: b.id
      }))
    } else {
      return []
    }
  }, [getBuildings.data])

  const energyClassOptionValues: ReadonlyArray<MenuItemEntry<EnergyClassOrInherit>> = React.useMemo(
    () =>
      [
        {
          label: t('enums.inclusion_option.Inherit'),
          value: InheritType.value
        } as MenuItemEntry<EnergyClassOrInherit>
      ].concat(
        getEnergyClasses.data?.map((energyClass) => ({
          label: translateValue(energyClass.title),
          value: energyClass.id
        })) ?? []
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, getEnergyClasses.data]
  )

  const isFormDisabled = false
  /*useMemo(
    () => hasValue(params.houseId) && !isEditableEntity(getHouse.data?.allowedActions),
    [params.houseId, getHouse.data]
  )
  */
  const isDeleteDisabled = React.useMemo(
    () => hasValue(params.houseId) && !isDeletableEntity(getHouse.data?.allowedActions),
    [params.houseId, getHouse.data]
  )

  const layoutHasApartments = hasValue(getLayoutType.data?.apartmentsCount)

  const optionalBooleanValues: ReadonlyArray<MenuItemEntry<OptionalType<InclusionOption>>> = React.useMemo(
    () => buildEnumOptions(InclusionOption, (v) => t(`enums.inclusion_option.${v}`)),
    [t]
  )

  React.useEffect(() => {
    if (!layoutHasApartments) {
      form.setValue('apartmentNumber', undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layoutHasApartments])

  const onDeleteHouse =
    (houseId: HouseId) =>
    (confirmResult: boolean): void => {
      if (confirmResult) {
        deleteHouse.mutate(houseId)
      }
    }

  const isLoading =
    (hasValue(getBuilding.data?.layoutTypeId) && getLayoutType.isFetching) ||
    (hasValue(selectedBuildingId) && getBuilding.isFetching) ||
    (hasValue(params.houseId) && getHouse.isFetching) ||
    createHouse.isLoading ||
    updateHouse.isLoading ||
    deleteHouse.isLoading

  return (
    <HouseFormStyled>
      <h1>{params.houseId ? t('pages.admin.house.edit.title') : t('pages.admin.house.new.title')}</h1>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" rowGap={2} mb={4}>
          {(isFormDisabled || isDeleteDisabled) && (
            <Alert severity="warning">
              {isFormDisabled
                ? t('pages.admin.house.messages.edit_restricted')
                : t('pages.admin.house.messages.delete_restricted')}
            </Alert>
          )}
          <FormTextFieldStyled
            name="buildingId"
            form={form}
            selectOptions={buildingOptionValues}
            label={t('pages.admin.house.form.field.building')}
            disabled={isLoading || isFormDisabled || hasValue(params.houseId)}
          />
          <FormTextFieldStyled
            name="code"
            form={form}
            type="text"
            label={t('pages.admin.house.form.field.code')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="cadastreNumber"
            form={form}
            type="text"
            label={t('pages.admin.house.form.field.cadastre_number')}
            disabled={isLoading || isFormDisabled}
          />
          {layoutHasApartments && (
            <FormTextFieldStyled
              name="apartmentNumber"
              form={form}
              type="text"
              label={t('pages.admin.house.form.field.apartment_number')}
              disabled={isLoading || isFormDisabled}
            />
          )}
          <FormTextFieldStyled
            name="floor"
            form={form}
            type="number"
            label={t('pages.admin.house.form.field.floor')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="outdoorSquare"
            form={form}
            type="number"
            label={t('pages.admin.house.form.field.outdoor_square')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="indoorSquare"
            form={form}
            type="number"
            label={t('pages.admin.house.form.field.indoor_square')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="storeRoomSquare"
            form={form}
            type="number"
            label={t('pages.admin.house.form.field.store_room_square')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="terraceSquare"
            form={form}
            type="number"
            label={t('pages.admin.house.form.field.terrace_square')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="roomsCount"
            form={form}
            type="number"
            label={t('pages.admin.house.form.field.rooms_count')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="bedroomsCount"
            form={form}
            type="number"
            label={t('pages.admin.house.form.field.bedrooms_count')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="hasSauna"
            form={form}
            select
            selectOptions={optionalBooleanValues}
            label={t('pages.admin.house.form.field.has_sauna')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="isInteriorIncluded"
            form={form}
            select
            selectOptions={optionalBooleanValues}
            label={t('pages.admin.house.form.field.is_interior_included')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="isSanitaryIncluded"
            form={form}
            select
            selectOptions={optionalBooleanValues}
            label={t('pages.admin.house.form.field.is_sanitary_included')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="energyClassId"
            form={form}
            select
            selectOptions={energyClassOptionValues}
            label={t('pages.admin.building.form.field.energy_class')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="immovableNumber"
            form={form}
            label={t('pages.admin.house.form.field.immovable_number')}
            disabled={isLoading || isFormDisabled}
          />
          <FormTextFieldStyled
            name="basePrice"
            form={form}
            type="number"
            label={t('pages.admin.house.form.field.base_price')}
            disabled={isLoading || isFormDisabled}
          />
          <FormDatePickerStyled
            name="handoffAt"
            form={form}
            label={t('pages.admin.house.form.field.handoff_date')}
            format={displayDateFormat}
            disabled={isLoading || isFormDisabled}
          />
          {!isFormDisabled && (
            <Box display="flex" columnGap={2} mt={2}>
              <Button disabled={isLoading || isFormDisabled} type="submit">
                {t('global.buttons.submit')}
              </Button>
              {hasValue(params.houseId) && (
                <ButtonWithConfirmation
                  color="error"
                  disabled={isLoading || isDeleteDisabled}
                  onConfirmResult={onDeleteHouse(params.houseId)}
                  confirmTitle={t('global.consent.delete.title')}
                  confirmMessage={t('global.consent.delete.message')}
                >
                  {t('global.buttons.delete')}
                </ButtonWithConfirmation>
              )}
            </Box>
          )}
        </Box>
      </form>
      {getHouse.data && (
        <HouseFormContext.Provider
          value={{ house: getHouse.data, building: getBuilding.data, layoutType: getLayoutType.data }}
        >
          <HouseFormTabs />
          <Box pt={2}>
            <Outlet />
          </Box>
        </HouseFormContext.Provider>
      )}
    </HouseFormStyled>
  )
}
